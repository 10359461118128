var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"top"},[(_vm.dataList.fowardText)?_c('div',{staticClass:"reply"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"tip"},[_vm._v("推荐转发文案:")]),_c('div',{staticClass:"copy",on:{"click":function($event){return _vm.copyText(_vm.dataList.fowardText)}}},[_vm._v("复制")])]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.dataList.fowardText))])])]):_vm._e(),_vm._m(0),_c('div',{staticClass:"code-image"},[_c('div',{staticClass:"code-border"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.posterType == 0),expression:"posterType == 0"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.posterImage),expression:"!posterImage"}],ref:"template",attrs:{"id":"code-template"}}),(_vm.posterImage)?_c('img',{style:({ height: _vm.bgHeight, width: '100%' }),attrs:{"src":_vm.posterImage,"alt":""}}):_vm._e()]),(_vm.posterType == 1)?_c('div',{ref:"template_simple",attrs:{"id":"code-template-simple"}},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.dataList.cardCorpLogo}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.dataList.cardCorpImageName))]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.dataList.cardCorpName))])])]),_c('div',{staticClass:"code"},[_c('img',{staticClass:"qrcode",attrs:{"src":_vm.dataList.qrcodeUrl}}),_c('img',{staticClass:"logo",attrs:{"src":_vm.dataList.cardCorpLogo}})]),_c('div',{staticClass:"tip"},[_vm._v("扫一扫上面的二维码图案")]),_c('div',{staticClass:"tip"},[_vm._v("加我企业微信")])]):_vm._e()])])]),_c('div',{staticClass:"bottom-box"},[_c('div',{staticClass:"long-btn",on:{"click":function($event){return _vm.$router.push(
          '/workFission/speed?id=' +
            _vm.fissionId +
            '&unionId=' +
            _vm.wxUserData.unionid
        )}}},[_vm._v(" 查看助力进度 ")])]),_c('input',{ref:"copyInput",staticStyle:{"position":"fixed","opacity":"0"},attrs:{"type":"text"}}),_c('canvas',{ref:"canvas",attrs:{"width":"720","height":"1280"}},[_vm._v(" 您的浏览器不支持canvas ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('div',{staticClass:"tip"},[_c('span',{staticClass:"text"},[_vm._v("长按保存专属海报分享给好友")])]),_c('div',{staticClass:"tip"},[_c('span',{staticClass:"text long-text"},[_vm._v("好友扫码添加微信后即助力成功")])])])
}]

export { render, staticRenderFns }